import { useState, useMemo, forwardRef } from 'react';
import { fromUnixTime, format } from 'date-fns';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { Paper, Unstable_Grid2 as Grid, Box, Slide, Tooltip } from '@mui/material';
import { Card, CardHeader, CardContent, Typography, Button, IconButton } from '@mui/material';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ArrowBack as ArrowBackIcon, Link as LinkIcon, Settings as SettingsIcon } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { useModalDialog, withModalDialog } from 'src/components/base/ModalDialog';
import { LicenseCard } from './LicenseCard';
import { AlertDialog } from 'src/components/base/AlertDialog';
import { SubscriptionFooter } from './SubscriptionFooter';
import { ShareSubscriptionDialog } from './ShareSubscriptionDialog';
import { SubscriptionTemplateDialog, usePlanTemplate } from './SubscriptionTemplateDialog';
import { SubscriptionTitle, SubscriptionSubheader } from './SubscriptionHeaders';
import { MultilineTypography } from 'src/components/base/MultilineTypography';
import { BottomBar } from 'src/components/base/BottomBar';
import { SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_IN_TRIAL } from 'shared/services/chargebee/constants';
import { sanitizeTemplateParameters } from 'shared/services/chargebee/utils';
import { useEffectState } from 'shared/hooks';

import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

export const SubscriptionDialog = withModalDialog(observer(({ onClose, ...props }) => {
  const { userStore } = useStore();

  const cancelDialog = useModalDialog();
  const removeScheduledCancellationDialog = useModalDialog();
  const shareSubscriptionDialog = useModalDialog();
  const subscriptionTemplateDialog = useModalDialog();

  const [subscription, setSubscription] = useState(toJS(props.subscription));
  const plan = useMemo(() => userStore.plans.find(({ id }) => id === subscription.data.plan_id), [userStore.plans, subscription.data.plan_id]);
  const template = usePlanTemplate(toJS(plan?.products));

  const [term, setTerm] = useEffectState(plan ? `${plan.period_unit}_${plan.period}` : 'year_1', [plan]);
  const plans = useMemo(() => {
    const origin_plan_id = subscription.origin?.plan_id;
    return userStore.plans.filter(({ id, status, period_unit, period }) =>
      term === `${period_unit}_${period}` && (status === SUBSCRIPTION_STATUS_ACTIVE || origin_plan_id === id)
    );
  }, [userStore.plans, term, subscription.origin?.plan_id]);

  const isAvailableNotprofit = useMemo(() => {
    const availablePlans = new Map(userStore.plans.map(({ id, nonprofit }) => [id, nonprofit]));
    return !userStore.subscriptions.some(({ key, data }) => subscription.key !== key && availablePlans.get(data.plan_id));
  }, [userStore.plans, subscription.key]);

  const isLoading = userStore.isLoading;
  const scheduled_cancellation = subscription.origin?.scheduled_cancellation;
  const isModified = subscription.data.plan_id != '' && !!subscription.data.licenses.length && !!subscription.estimates;

  const timestampValidThrough = subscription.origin?.valid_through && format(fromUnixTime(subscription.origin.valid_through), 'PPP');

  function handleChangeName(name) {
    inplaceUpdateSubscription({ name });
  }

  function handleChangeTerm(event) {
    setTerm(event.target.value)
    inplaceUpdateSubscription({ plan_id: '' });
  }

  async function handleChangePlan(event) {
    const plan_id = event.target.value;
    const plan = userStore.plans.find(({ id }) => id === plan_id);

    const { products } = sanitizeTemplateParameters(plan?.products, subscription.data.products);
    subscription.data.products = products;

    await updateSubscription({ plan_id, products });
  }

  async function handleShareSubscription() {
    await shareSubscriptionDialog.open({ subscriptionKey: subscription.key });
  }

  async function handleManageTemplate() {
    const products = await subscriptionTemplateDialog.open({ template, products: subscription.data.products ?? [] });
    if (!!products) {
      inplaceUpdateSubscription({ products });
    }
  }

  async function handleCancelSubscription() {
    if (await cancelDialog.open()) {
      await runAction(async () => {
        setSubscription(await userStore.cancelSubscription(subscription.key));
      })
    }
  }

  async function handleRemoveScheduledCancellation() {
    if (await removeScheduledCancellationDialog.open()) {
      await runAction(async () => {
        setSubscription(await userStore.removeScheduledCancellation(subscription.key));
      })
    }
  }

  async function handleUpdateLicenses(licenses) {
    await updateSubscription({ licenses });
  }

  async function handleSaveClick() {
    const { data = {}, origin = {} } = subscription;
    if (data.status !== origin.status || data.plan_id !== origin.plan_id || data.licenses?.length !== origin.licenses?.length) {
      return onClose(subscription);
    }

    await runAction(async () => {
      await userStore.updateSubscriptions(subscription);
      onClose();
    })
  }

  function inplaceUpdateSubscription(diff) {
    const data = { ...subscription.data, ...diff };
    setSubscription({ ...subscription, data, estimates: data.plan_id ? (subscription.estimates || {}) : null });
  }

  async function updateSubscription(diff) {
    const { data = {}, origin = {} } = subscription;
    const value = { ...data, ...diff };

    if (!value.plan_id || (value.plan_id === origin.plan_id
      && value.licenses.length === origin.licenses?.length
      && value.licenses.length === data.licenses?.length)) {
      return inplaceUpdateSubscription(value);
    }

    await runAction(async () => {
      setSubscription(await userStore.estimateSubscription(subscription, value));
    })
  }

  return <>
    <Grid container direction="column" alignItems="center" wrap="nowrap" sx={{ backgroundColor: '#FAFAFA', pb: '56px', flex: '1 0 auto' }}>
      <Paper sx={{ width: 1, height: 48 }}>
        <Grid container alignItems="center">
          <IconButton onClick={() => onClose()} size="large"><ArrowBackIcon /></IconButton>
          <Typography variant="h6" noWrap>Subscription Management</Typography>
        </Grid>
      </Paper>

      <Grid container justifyContent="center" xs={11} md={7} direction="column" wrap="nowrap" sx={{ mt: 1, flex: '1 0 auto' }}>
        <Grid xs="auto">
          <Card>
            <CardHeader
              title={<SubscriptionTitle title={subscription.data?.name} defaultTitle={'Enter subscription name'} onChange={handleChangeName} />}
              subheader={<SubscriptionSubheader subscription={subscription} />}
              action={
                <>
                  {!!template.length &&
                    <Tooltip title="Configure subscription features">
                      <IconButton onClick={handleManageTemplate} size="large">
                        <SettingsIcon />
                      </IconButton>
                    </Tooltip>
                  }

                  {!!subscription.origin?.id &&
                    <Tooltip title="Delegate User Management">
                      <IconButton onClick={handleShareSubscription} size="large">
                        <LinkIcon />
                      </IconButton>
                    </Tooltip>
                  }
                </>
              }
            />
            <CardContent>
              <Grid container direction="row" wrap="wrap" justifyContent="space-between" spacing={2}>
                <Grid xs={12} md={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="term">Billing Period</InputLabel>
                    <Select value={term} onChange={handleChangeTerm} inputProps={{ id: 'term' }} disabled={isLoading}>
                      <MenuItem value="month_1">Monthly</MenuItem>
                      <MenuItem value="year_1">Annual</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid xs={12} md={6}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel htmlFor="plan">Select Plan</InputLabel>
                    <Select value={plan?.id ?? ''} onChange={handleChangePlan} inputProps={{ id: 'plan' }} disabled={isLoading}>
                      {
                        plans.map(i => (
                          <MenuItem key={i.id} value={i.id} disabled={i.nonprofit && !isAvailableNotprofit}>{i.name}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>

              </Grid>
            </CardContent>

            <CardContent>
              <MultilineTypography variant="caption" description={plan?.description} />
            </CardContent>

            <SubscriptionFooter subscription={subscription} onChange={setSubscription} />

          </Card>
        </Grid>

        <Grid xs sx={{ my: 1 }}>
          <LicenseCard subscription={subscription.origin?.id} licenses={subscription.data?.licenses ?? []}
            onUpdate={handleUpdateLicenses} updated_at={subscription.data.updated_at} disabled={isLoading} token={userStore.token}
          />
        </Grid>
      </Grid>

      <BottomBar position="fixed" color="default">
        <Grid container xs={11} md={7} alignItems="flex-start" justifyContent="space-between">
          <Grid>
            <Button color="inherit" disabled={isLoading} onClick={() => onClose()}>Cancel</Button>
            <LoadingButton color="primary" disabled={isLoading || !isModified} loading={isLoading} variant="contained" onClick={handleSaveClick} sx={{ ml: 1 }}>Apply</LoadingButton>
          </Grid>
          {[SUBSCRIPTION_STATUS_ACTIVE, SUBSCRIPTION_STATUS_IN_TRIAL].includes(subscription.origin?.status) && !scheduled_cancellation &&
            <LoadingButton color="secondary" disabled={isLoading} loading={isLoading} onClick={handleCancelSubscription}>
              Cancel Subscription
            </LoadingButton>
          }
          {scheduled_cancellation &&
            <LoadingButton color="secondary" disabled={isLoading} loading={isLoading} onClick={handleRemoveScheduledCancellation}>
              Remove Scheduled Cancellation
            </LoadingButton>
          }
        </Grid>
      </BottomBar>

      <ShareSubscriptionDialog {...shareSubscriptionDialog.register()} />

      <SubscriptionTemplateDialog {...subscriptionTemplateDialog.register()} />

      <AlertDialog {...cancelDialog.register()} title="Cancel Subscription" cancelable>
        Your subscription will remain active until the end of the current period and will cancel automatically on {timestampValidThrough}
      </AlertDialog>

      <AlertDialog {...removeScheduledCancellationDialog.register()} title="Confirmation" cancelable>
        Do you want to remove scheduled cancellation?
      </AlertDialog>

    </Grid>
  </>;
}), { fullScreen: true, TransitionComponent: forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />) })
