import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { translateCode, validatorResolver } from 'src/services/validation';
import { observer } from 'mobx-react';
import { Unstable_Grid2 as Grid, TextField, Typography } from '@mui/material';
import { Card, CardContent, CardActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { runAction } from 'src/services/utils';
import { useStore } from 'src/stores';

export const ResetPassword = observer(() => {
  const { userStore } = useStore();
  const navigate = useNavigate();

  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({ email: ['required', 'email'] })
  });

  async function handleResetPassword(data) {
    await runAction(async () => {
      await userStore.resetPassword(data);
      userStore.displaySuccessSnack(`If your email exists in our system, we’ll send you an email with instructions to reset your password. Please check your Inbox`);
      navigate('/', { replace: true });
    });
  }

  return (
    <Grid container xs={11} md={6} alignContent="center" justifyContent="center" direction="column">
      <Card>
        <form onSubmit={handleSubmit(handleResetPassword)} noValidate>
          <CardContent>
            <Typography variant="h5" color="textSecondary" gutterBottom>Forgot your password?</Typography>
            <Typography variant="caption" paragraph>Enter your email address below and we'll send you a link to reset your password.</Typography>

            <TextField name="email" autoComplete="username" autoFocus fullWidth type="email" variant="filled" label="Email"
              error={!!errors.email} helperText={translateCode(errors.email)} {...register('email')} />
          </CardContent>

          <CardActions>
            <LoadingButton type="submit" loading={userStore.isLoading} color="secondary" variant="contained">Send Reset Link</LoadingButton>
          </CardActions>
        </form>
      </Card>
    </Grid>
  )
})
