import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { Unstable_Grid2 as Grid, TextField, Typography } from '@mui/material';
import { Card, CardContent, CardActions } from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { PasswordMetter } from 'src/components/base/PasswordMetter';
import { translateCode, validatorResolver } from 'src/services/validation';
import { omit } from 'shared/utils/utils';
import { cookie } from 'shared/utils/cookies';
import { scorePassword } from 'shared/passwords/scorePassword';
import { runAction } from 'src/services/utils';
import { useGoogleAnalytics } from 'src/services/googleAnalytics';
import { useStore } from 'src/stores';
import { CODE_EXISTING_USER, CODE_NEED_ACTIVATION } from 'shared/status-error/codes';

export const Register = observer(() => {
  const { userStore } = useStore();

  const navigate = useNavigate();
  const params = useParams();

  const gtag = useGoogleAnalytics();

  const { register, handleSubmit, formState: { errors }, watch } = useForm({
    resolver: validatorResolver({
      first_name: ['required', 'string'], last_name: ['required', 'string'], email: ['required', 'email'],
      password: { predicate: value => scorePassword(value) ? '' : 'TOO_SHORT' },
      password2: ['required', { equal_to_field: 'password' }],
    })
  });

  const password = watch('password')
  const passwordScore = scorePassword(password);

  async function handleRegister(data) {
    const referrer = params.referrer ?? cookie('referrer');
    await runAction(async () => {
      try {
        await userStore.register({ ...omit(data, ['password2']), referrer });

        userStore.displaySuccessSnack(`We've sent you email with instruction. Please check your mail box.`);
        gtag('event', 'sign_up', {});
      }
      catch (error) {
        if (error.code !== CODE_EXISTING_USER && error.code !== CODE_NEED_ACTIVATION) { throw error }

        const message = error.code === CODE_EXISTING_USER
          ? 'User already registered. Please log in to the portal using your user name and password. If you have forgotten your password, please click on the ‘Forgot Password?’ link.'
          : 'This user is pending activation. If you did not receive the activation email, please click on the ‘Forgot Password?’ link to resend it.';
        userStore.displayErrorSnack(message);
      }

      navigate('/', { replace: true });
    })
  }

  return (
    <Grid container xs={11} md={6} alignContent="center" justifyContent="center" direction="column">
      <Card>
        <form onSubmit={handleSubmit(handleRegister)} noValidate>
          <CardContent>
            <Typography variant="h5" color="textSecondary" gutterBottom>Create an Account</Typography>
            <Typography variant="caption" paragraph>Registering for this portal is easy, just fill in the fields bellow and we'll get a new account set up for you in no time.</Typography>

            <Grid container spacing={2}>
              <Grid xs={12} sm={6}>
                <TextField name="first_name" label="First Name" variant="filled" autoFocus fullWidth
                  error={!!errors.first_name} helperText={translateCode(errors.first_name)} {...register('first_name')}
                />
              </Grid>

              <Grid xs={12} sm={6}>
                <TextField name="last_name" label="Last Name" fullWidth variant="filled"
                  error={!!errors.last_name} helperText={translateCode(errors.last_name)} {...register('last_name')}
                />
              </Grid>
            </Grid>

            <TextField name="email" label="Email" type="email" autoComplete="username" fullWidth variant="filled" margin="normal"
              error={!!errors.email} helperText={translateCode(errors.email)} {...register('email')}
            />

            <TextField name="password" label="Password" type="password" autoComplete="new-password" fullWidth variant="filled" margin="dense" placeholder="At least 8 characters"
              error={!!errors.password} helperText={translateCode(errors.password)} {...register('password')}
            />

            <PasswordMetter score={passwordScore} empty={!password?.length} />

            <TextField name="password2" autoComplete="new-password" label="Retype Password" type="password" fullWidth variant="filled" margin="normal"
              error={!!errors.password2} helperText={translateCode(errors.password2)} {...register('password2')}
            />
          </CardContent>

          <CardActions>
            <LoadingButton loading={userStore.isLoading} type="submit" color="secondary" variant="contained">Create Account</LoadingButton>
          </CardActions>
        </form>
      </Card>
    </Grid>
  )
})